export var Regions = [
  { name: 'Afghanistan', name_cn: '阿富汗', code: 'af', emoji: '🇦🇫' },
  { name: 'Albania', name_cn: '阿尔巴尼亚', code: 'al', emoji: '🇦🇱' },
  { name: 'Algeria', name_cn: '阿尔及利亚', code: 'dz', emoji: '🇩🇿' },
  { name: 'American Samoa', name_cn: '美属萨摩亚', code: 'as', emoji: '🇦🇸' },
  { name: 'Andorra', name_cn: '安道尔', code: 'ad', emoji: '🇦🇩' },
  { name: 'Angola', name_cn: '安哥拉', code: 'ao', emoji: '🇦🇴' },
  { name: 'Anguilla', name_cn: '安圭拉', code: 'ai', emoji: '🇦🇮' },
  { name: 'Antarctica', name_cn: '南极洲', code: 'aq', emoji: '🇦🇶' },
  {
    name: 'Antigua and Barbuda',
    name_cn: '安提瓜和巴布达',
    code: 'ag',
    emoji: '🇦🇬',
  },
  { name: 'Argentina', name_cn: '阿根廷', code: 'ar', emoji: '🇦🇷' },
  { name: 'Armenia', name_cn: '亚美尼亚', code: 'am', emoji: '🇦🇲' },
  { name: 'Aruba', name_cn: '阿鲁巴', code: 'aw', emoji: '🇦🇼' },
  { name: 'Australia', name_cn: '澳大利亚', code: 'au', emoji: '🇦🇺' },
  { name: 'Austria', name_cn: '奥地利', code: 'at', emoji: '🇦🇹' },
  { name: 'Azerbaijan', name_cn: '阿塞拜疆', code: 'az', emoji: '🇦🇿' },
  { name: 'Bahamas', name_cn: '巴哈马', code: 'bs', emoji: '🇧🇸' },
  { name: 'Bahrain', name_cn: '巴林', code: 'bh', emoji: '🇧🇭' },
  { name: 'Bangladesh', name_cn: '孟加拉国', code: 'bd', emoji: '🇧🇩' },
  { name: 'Barbados', name_cn: '巴巴多斯', code: 'bb', emoji: '🇧🇧' },
  { name: 'Belarus', name_cn: '白俄罗斯', code: 'by', emoji: '🇧🇾' },
  { name: 'Belgium', name_cn: '比利时', code: 'be', emoji: '🇧🇪' },
  { name: 'Belize', name_cn: '伯利兹', code: 'bz', emoji: '🇧🇿' },
  { name: 'Benin', name_cn: '贝宁', code: 'bj', emoji: '🇧🇯' },
  { name: 'Bermuda', name_cn: '百慕大', code: 'bm', emoji: '🇧🇲' },
  { name: 'Bhutan', name_cn: '不丹', code: 'bt', emoji: '🇧🇹' },
  { name: 'Bolivia', name_cn: '玻利维亚', code: 'bo', emoji: '🇧🇴' },
  {
    name: 'Bosnia and Herzegovina',
    name_cn: '波斯尼亚和黑塞哥维那',
    code: 'ba',
    emoji: '🇧🇦',
  },
  { name: 'Botswana', name_cn: '博茨瓦纳', code: 'bw', emoji: '🇧🇼' },
  { name: 'Bouvet Island', name_cn: '布韦岛', code: 'bv', emoji: '🇧🇻' },
  { name: 'Brazil', name_cn: '巴西', code: 'br', emoji: '🇧🇷' },
  {
    name: 'British Indian Ocean Territory',
    name_cn: '英属印度洋领地',
    code: 'io',
    emoji: '🇮🇴',
  },
  {
    name: 'Brunei Darussalam',
    name_cn: '文莱达鲁萨兰国',
    code: 'bn',
    emoji: '🇧🇳',
  },
  { name: 'Bulgaria', name_cn: '保加利亚', code: 'bg', emoji: '🇧🇬' },
  { name: 'Burkina Faso', name_cn: '布基纳法索', code: 'bf', emoji: '🇧🇫' },
  { name: 'Burundi', name_cn: '布隆迪', code: 'bi', emoji: '🇧🇮' },
  { name: 'Cambodia', name_cn: '柬埔寨', code: 'kh', emoji: '🇰🇭' },
  { name: 'Cameroon', name_cn: '喀麦隆', code: 'cm', emoji: '🇨🇲' },
  { name: 'Canada', name_cn: '加拿大', code: 'ca', emoji: '🇨🇦' },
  { name: 'Cape Verde', name_cn: '佛得角', code: 'cv', emoji: '🇨🇻' },
  { name: 'Cayman Islands', name_cn: '开曼群岛', code: 'ky', emoji: '🇰🇾' },
  {
    name: 'Central African Republic',
    name_cn: '中非共和国',
    code: 'cf',
    emoji: '🇨🇫',
  },
  { name: 'Chad', name_cn: '乍得', code: 'td', emoji: '🇹🇩' },
  { name: 'Chile', name_cn: '智利', code: 'cl', emoji: '🇨🇱' },
  { name: 'China', name_cn: '中国', code: 'cn', emoji: '🇨🇳' },
  { name: 'Christmas Island', name_cn: '圣诞岛', code: 'cx', emoji: '🇨🇽' },
  {
    name: 'Cocos (Keeling) Islands',
    name_cn: '科科斯(基林)群岛',
    code: 'cc',
    emoji: '🇨🇨',
  },
  { name: 'Colombia', name_cn: '哥伦比亚', code: 'co', emoji: '🇨🇴' },
  { name: 'Comoros', name_cn: '科摩罗', code: 'km', emoji: '🇰🇲' },
  { name: 'Congo', name_cn: '刚果', code: 'cg', emoji: '🇨🇬' },
  {
    name: 'Congo, the Democratic Republic of the',
    name_cn: '刚果民主共和国',
    code: 'cd',
    emoji: '🇨🇩',
  },
  { name: 'Cook Islands', name_cn: '库克群岛', code: 'ck', emoji: '🇨🇰' },
  { name: 'Costa Rica', name_cn: '哥斯达黎加', code: 'cr', emoji: '🇨🇷' },
  { name: "Cote D'ivoire", name_cn: '科特迪瓦', code: 'ci', emoji: '🇨🇮' },
  { name: 'Croatia', name_cn: '克罗地亚', code: 'hr', emoji: '🇭🇷' },
  { name: 'Cuba', name_cn: '古巴', code: 'cu', emoji: '🇨🇺' },
  { name: 'Cyprus', name_cn: '塞浦路斯', code: 'cy', emoji: '🇨🇾' },
  { name: 'Czech Republic', name_cn: '捷克共和国', code: 'cz', emoji: '🇨🇿' },
  { name: 'Denmark', name_cn: '丹麦', code: 'dk', emoji: '🇩🇰' },
  { name: 'Djibouti', name_cn: '吉布提', code: 'dj', emoji: '🇩🇯' },
  { name: 'Dominica', name_cn: '多米尼加', code: 'dm', emoji: '🇩🇲' },
  {
    name: 'Dominican Republic',
    name_cn: '多米尼加共和国',
    code: 'do',
    emoji: '🇩🇴',
  },
  { name: 'Ecuador', name_cn: '厄瓜多尔', code: 'ec', emoji: '🇪🇨' },
  { name: 'Egypt', name_cn: '埃及', code: 'eg', emoji: '🇪🇬' },
  { name: 'El Salvador', name_cn: '萨尔瓦多', code: 'sv', emoji: '🇸🇻' },
  { name: 'Equatorial Guinea', name_cn: '赤道几内亚', code: 'gq', emoji: '🇬🇶' },
  { name: 'Eritrea', name_cn: '厄立特里亚', code: 'er', emoji: '🇪🇷' },
  { name: 'Estonia', name_cn: '爱沙尼亚', code: 'ee', emoji: '🇪🇪' },
  { name: 'Ethiopia', name_cn: '埃塞俄比亚', code: 'et', emoji: '🇪🇹' },
  {
    name: 'Falkland Islands (Malvinas)',
    name_cn: '福克兰群岛(马尔维纳斯)',
    code: 'fk',
    emoji: '🇫🇰',
  },
  { name: 'Faroe Islands', name_cn: '法罗群岛', code: 'fo', emoji: '🇫🇴' },
  { name: 'Fiji', name_cn: '斐济', code: 'fj', emoji: '🇫🇯' },
  { name: 'Finland', name_cn: '芬兰', code: 'fi', emoji: '🇫🇮' },
  { name: 'France', name_cn: '法国', code: 'fr', emoji: '🇫🇷' },
  { name: 'French Guiana', name_cn: '法属圭亚那', code: 'gf', emoji: '🇬🇫' },
  {
    name: 'French Polynesia',
    name_cn: '法属波利尼西亚',
    code: 'pf',
    emoji: '🇵🇫',
  },
  {
    name: 'French Southern Territories',
    name_cn: '法属南部领土',
    code: 'tf',
    emoji: '🇹🇫',
  },
  { name: 'Gabon', name_cn: '加蓬', code: 'ga', emoji: '🇬🇦' },
  { name: 'Gambia', name_cn: '冈比亚', code: 'gm', emoji: '🇬🇲' },
  { name: 'Georgia', name_cn: '格鲁吉亚', code: 'ge', emoji: '🇬🇪' },
  { name: 'Germany', name_cn: '德国', code: 'de', emoji: '🇩🇪' },
  { name: 'Ghana', name_cn: '加纳', code: 'gh', emoji: '🇬🇭' },
  { name: 'Gibraltar', name_cn: '直布罗陀', code: 'gi', emoji: '🇬🇮' },
  { name: 'Greece', name_cn: '希腊', code: 'gr', emoji: '🇬🇷' },
  { name: 'Greenland', name_cn: '格陵兰', code: 'gl', emoji: '🇬🇱' },
  { name: 'Grenada', name_cn: '格林纳达', code: 'gd', emoji: '🇬🇩' },
  { name: 'Guadeloupe', name_cn: '瓜德罗普岛', code: 'gp', emoji: '🇬🇵' },
  { name: 'Guam', name_cn: '关岛', code: 'gu', emoji: '🇬🇺' },
  { name: 'Guatemala', name_cn: '危地马拉', code: 'gt', emoji: '🇬🇹' },
  { name: 'Guernsey', name_cn: '根西岛', code: 'gg', emoji: '🇬🇬' },
  { name: 'Guinea', name_cn: '几内亚', code: 'gn', emoji: '🇬🇳' },
  { name: 'Guinea-Bissau', name_cn: '几内亚比绍', code: 'gw', emoji: '🇬🇼' },
  { name: 'Guyana', name_cn: '圭亚那', code: 'gy', emoji: '🇬🇾' },
  { name: 'Haiti', name_cn: '海地', code: 'ht', emoji: '🇭🇹' },
  {
    name: 'Heard Island and Mcdonald Islands',
    name_cn: '赫德岛和麦克唐纳群岛',
    code: 'hm',
    emoji: '🇭🇲',
  },
  {
    name: 'Holy See (Vatican City State)',
    name_cn: '梵蒂冈城国',
    code: 'va',
    emoji: '🇻🇦',
  },
  { name: 'Honduras', name_cn: '洪都拉斯', code: 'hn', emoji: '🇭🇳' },
  { name: 'Hong Kong', name_cn: '中国香港', code: 'hk', emoji: '🇨🇳' },
  { name: 'Hungary', name_cn: '匈牙利', code: 'hu', emoji: '🇭🇺' },
  { name: 'Iceland', name_cn: '冰岛', code: 'is', emoji: '🇮🇸' },
  { name: 'India', name_cn: '印度', code: 'in', emoji: '🇮🇳' },
  { name: 'Indonesia', name_cn: '印尼', code: 'id', emoji: '🇮🇩' },
  {
    name: 'Iran, Islamic Republic of',
    name_cn: '伊朗伊斯兰共和国',
    code: 'ir',
    emoji: '🇮🇷',
  },
  { name: 'Iraq', name_cn: '伊拉克', code: 'iq', emoji: '🇮🇶' },
  { name: 'Ireland', name_cn: '爱尔兰', code: 'ie', emoji: '🇮🇪' },
  { name: 'Isle of Man', name_cn: '马恩岛', code: 'im', emoji: '🇮🇲' },
  { name: 'Israel', name_cn: '以色列', code: 'il', emoji: '🇮🇱' },
  { name: 'Italy', name_cn: '意大利', code: 'it', emoji: '🇮🇹' },
  { name: 'Jamaica', name_cn: '牙买加', code: 'jm', emoji: '🇯🇲' },
  { name: 'Japan', name_cn: '日本', code: 'jp', emoji: '🇯🇵' },
  { name: 'Jersey', name_cn: '泽西岛', code: 'je', emoji: '🇯🇪' },
  { name: 'Jordan', name_cn: '约旦', code: 'jo', emoji: '🇯🇴' },
  { name: 'Kazakhstan', name_cn: '哈萨克斯坦', code: 'kz', emoji: '🇰🇿' },
  { name: 'Kenya', name_cn: '肯尼亚', code: 'ke', emoji: '🇰🇪' },
  { name: 'Kiribati', name_cn: '基里巴斯', code: 'ki', emoji: '🇰🇮' },
  {
    name: "Korea, Democratic People's Republic of",
    name_cn: '朝鲜民主主义人民共和国',
    code: 'kp',
    emoji: '🇰🇵',
  },
  { name: 'Republic of Korea', name_cn: '韩国', code: 'kr', emoji: '🇰🇷' },
  { name: 'Kuwait', name_cn: '科威特', code: 'kw', emoji: '🇰🇼' },
  { name: 'Kyrgyzstan', name_cn: '吉尔吉斯斯坦', code: 'kg', emoji: '🇰🇬' },
  {
    name: "Lao People's Democratic Republic",
    name_cn: '老挝人民民主共和国',
    code: 'la',
    emoji: '🇱🇦',
  },
  { name: 'Latvia', name_cn: '拉脱维亚', code: 'lv', emoji: '🇱🇻' },
  { name: 'Lebanon', name_cn: '黎巴嫩', code: 'lb', emoji: '🇱🇧' },
  { name: 'Lesotho', name_cn: '莱索托', code: 'ls', emoji: '🇱🇸' },
  { name: 'Liberia', name_cn: '利比里亚', code: 'lr', emoji: '🇱🇷' },
  {
    name: 'Libyan Arab Jamahiriya',
    name_cn: '利比亚阿拉伯民众国',
    code: 'ly',
    emoji: '🇱🇾',
  },
  {
    name: 'Liechtenstein',
    name_cn: '列支敦士登',
    code: 'li',
    emoji: '🇱🇮',
  },
  { name: 'Lithuania', name_cn: '立陶宛', code: 'lt', emoji: '🇱🇹' },
  { name: 'Luxembourg', name_cn: '卢森堡', code: 'lu', emoji: '🇱🇺' },
  { name: 'Macao', name_cn: '中国澳门', code: 'mo', emoji: '🇨🇳' },
  { name: 'North Macedonia', name_cn: '北马其顿', code: 'mk', emoji: '🇲🇰' },
  { name: 'Madagascar', name_cn: '马达加斯加', code: 'mg', emoji: '🇲🇬' },
  { name: 'Malawi', name_cn: '马拉维', code: 'mw', emoji: '🇲🇼' },
  { name: 'Malaysia', name_cn: '马来西亚', code: 'my', emoji: '🇲🇾' },
  { name: 'Maldives', name_cn: '马尔代夫', code: 'mv', emoji: '🇲🇻' },
  { name: 'Mali', name_cn: '马里', code: 'ml', emoji: '🇲🇱' },
  { name: 'Malta', name_cn: '马耳他', code: 'mt', emoji: '🇲🇹' },
  { name: 'Marshall Islands', name_cn: '马绍尔群岛', code: 'mh', emoji: '🇲🇭' },
  { name: 'Martinique', name_cn: '马提尼克', code: 'mq', emoji: '🇲🇶' },
  { name: 'Mauritania', name_cn: '毛里塔尼亚', code: 'mr', emoji: '🇲🇷' },
  { name: 'Mauritius', name_cn: '毛里求斯', code: 'mu', emoji: '🇲🇺' },
  { name: 'Mayotte', name_cn: '马约特岛', code: 'yt', emoji: '🇾🇹' },
  { name: 'Mexico', name_cn: '墨西哥', code: 'mx', emoji: '🇲🇽' },
  {
    name: 'Micronesia, Federated States of',
    name_cn: '密克罗尼西亚联邦',
    code: 'fm',
    emoji: '🇫🇲',
  },
  {
    name: 'Moldova, Republic of',
    name_cn: '摩尔多瓦共和国',
    code: 'md',
    emoji: '🇲🇩',
  },
  { name: 'Monaco', name_cn: '摩纳哥', code: 'mc', emoji: '🇲🇨' },
  { name: 'Mongolia', name_cn: '蒙古国', code: 'mn', emoji: '🇲🇳' },
  { name: 'Montenegro', name_cn: '黑山共和国', code: 'me', emoji: '🇲🇪' },
  { name: 'Montserrat', name_cn: '蒙特塞拉特', code: 'ms', emoji: '🇲🇸' },
  { name: 'Morocco', name_cn: '摩洛哥', code: 'ma', emoji: '🇲🇦' },
  { name: 'Mozambique', name_cn: '莫桑比克', code: 'mz', emoji: '🇲🇿' },
  { name: 'Myanmar', name_cn: '缅甸', code: 'mm', emoji: '🇲🇲' },
  { name: 'Namibia', name_cn: '纳米比亚', code: 'na', emoji: '🇳🇦' },
  { name: 'Nauru', name_cn: '瑙鲁', code: 'nr', emoji: '🇳🇷' },
  { name: 'Nepal', name_cn: '尼泊尔', code: 'np', emoji: '🇳🇵' },
  { name: 'Netherlands', name_cn: '荷兰', code: 'nl', emoji: '🇳🇱' },
  {
    name: 'Netherlands Antilles',
    name_cn: '荷属安的列斯',
    code: 'an',
    emoji: '🇦🇳',
  },
  { name: 'New Caledonia', name_cn: '新喀里多尼亚', code: 'nc', emoji: '🇳🇨' },
  { name: 'New Zealand', name_cn: '新西兰', code: 'nz', emoji: '🇳🇿' },
  { name: 'Nicaragua', name_cn: '尼加拉瓜', code: 'ni', emoji: '🇳🇮' },
  { name: 'Niger', name_cn: '尼日尔', code: 'ne', emoji: '🇳🇪' },
  { name: 'Nigeria', name_cn: '尼日利亚', code: 'ng', emoji: '🇳🇬' },
  { name: 'Niue', name_cn: '纽埃', code: 'nu', emoji: '🇳🇺' },
  { name: 'Norfolk Island', name_cn: '诺福克岛', code: 'nf', emoji: '🇳🇫' },
  {
    name: 'Northern Mariana Islands',
    name_cn: '北马里亚纳群岛',
    code: 'mp',
    emoji: '🇲🇵',
  },
  { name: 'Norway', name_cn: '挪威', code: 'no', emoji: '🇳🇴' },
  { name: 'Oman', name_cn: '阿曼', code: 'om', emoji: '🇴🇲' },
  { name: 'Pakistan', name_cn: '巴基斯坦', code: 'pk', emoji: '🇵🇰' },
  { name: 'Palau', name_cn: '帕劳', code: 'pw', emoji: '🇵🇼' },
  {
    name: 'Palestinian Territory, Occupied',
    name_cn: '巴勒斯坦领土',
    code: 'ps',
    emoji: '🇵🇸',
  },
  { name: 'Panama', name_cn: '巴拿马', code: 'pa', emoji: '🇵🇦' },
  {
    name: 'Papua New Guinea',
    name_cn: '巴布亚新几内亚',
    code: 'pg',
    emoji: '🇵🇬',
  },
  { name: 'Paraguay', name_cn: '巴拉圭', code: 'py', emoji: '🇵🇾' },
  { name: 'Peru', name_cn: '秘鲁', code: 'pe', emoji: '🇵🇪' },
  { name: 'Philippines', name_cn: '菲律宾', code: 'ph', emoji: '🇵🇭' },
  { name: 'Pitcairn', name_cn: '皮特凯恩', code: 'pn', emoji: '🇵🇳' },
  { name: 'Poland', name_cn: '波兰', code: 'pl', emoji: '🇵🇱' },
  { name: 'Portugal', name_cn: '葡萄牙', code: 'pt', emoji: '🇵🇹' },
  { name: 'Puerto Rico', name_cn: '波多黎各', code: 'pr', emoji: '🇵🇷' },
  { name: 'Qatar', name_cn: '卡塔尔', code: 'qa', emoji: '🇶🇦' },
  { name: 'Reunion', name_cn: '留尼汪', code: 're', emoji: '🇷🇪' },
  { name: 'Romania', name_cn: '罗马尼亚', code: 'ro', emoji: '🇷🇴' },
  {
    name: 'Russian Federation',
    name_cn: '俄罗斯联邦',
    code: 'ru',
    emoji: '🇷🇺',
  },
  { name: 'Rwanda', name_cn: '卢旺达', code: 'rw', emoji: '🇷🇼' },
  { name: 'Saint Helena', name_cn: '圣赫勒拿', code: 'sh', emoji: '🇸🇭' },
  {
    name: 'Saint Kitts and Nevis',
    name_cn: '圣基茨和尼维斯',
    code: 'kn',
    emoji: '🇰🇳',
  },
  { name: 'Saint Lucia', name_cn: '圣卢西亚', code: 'lc', emoji: '🇱🇨' },
  {
    name: 'Saint Pierre and Miquelon',
    name_cn: '圣皮埃尔和密克隆群岛',
    code: 'pm',
    emoji: '🇵🇲',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    name_cn: '圣文森特和格林纳丁斯',
    code: 'vc',
    emoji: '🇻🇨',
  },
  { name: 'Samoa', name_cn: '萨摩亚', code: 'ws', emoji: '🇼🇸' },
  { name: 'San Marino', name_cn: '圣马力诺', code: 'sm', emoji: '🇸🇲' },
  {
    name: 'Sao Tome and Principe',
    name_cn: '圣多美和普林西比',
    code: 'st',
    emoji: '🇸🇹',
  },
  { name: 'Saudi Arabia', name_cn: '沙特阿拉伯', code: 'sa', emoji: '🇸🇦' },
  { name: 'Senegal', name_cn: '塞内加尔', code: 'sn', emoji: '🇸🇳' },
  { name: 'Serbia', name_cn: '塞尔维亚', code: 'rs', emoji: '🇷🇸' },
  { name: 'Seychelles', name_cn: '塞舌尔', code: 'sc', emoji: '🇸🇨' },
  { name: 'Sierra Leone', name_cn: '塞拉利昂', code: 'sl', emoji: '🇸🇱' },
  { name: 'Singapore', name_cn: '新加坡', code: 'sg', emoji: '🇸🇬' },
  { name: 'Slovakia', name_cn: '斯洛伐克', code: 'sk', emoji: '🇸🇰' },
  { name: 'Slovenia', name_cn: '斯洛文尼亚', code: 'si', emoji: '🇸🇮' },
  { name: 'Solomon Islands', name_cn: '所罗门群岛', code: 'sb', emoji: '🇸🇧' },
  { name: 'Somalia', name_cn: '索马里', code: 'so', emoji: '🇸🇴' },
  { name: 'South Africa', name_cn: '南非', code: 'za', emoji: '🇿🇦' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    name_cn: '南乔治亚和南桑威奇群岛',
    code: 'gs',
    emoji: '🇬🇸',
  },
  { name: 'South Sudan', name_cn: '南苏丹', code: 'ss', emoji: '🇸🇸' },
  { name: 'Spain', name_cn: '西班牙', code: 'es', emoji: '🇪🇸' },
  { name: 'Sri Lanka', name_cn: '斯里兰卡', code: 'lk', emoji: '🇱🇰' },
  { name: 'Sudan', name_cn: '苏丹', code: 'sd', emoji: '🇸🇩' },
  { name: 'Suriname', name_cn: '苏里南', code: 'sr', emoji: '🇸🇷' },
  {
    name: 'Svalbard and Jan Mayen',
    name_cn: '斯瓦尔巴和扬马延',
    code: 'sj',
    emoji: '🇸🇯',
  },
  { name: 'Swaziland', name_cn: '斯威士兰', code: 'sz', emoji: '🇸🇿' },
  { name: 'Sweden', name_cn: '瑞典', code: 'se', emoji: '🇸🇪' },
  { name: 'Switzerland', name_cn: '瑞士', code: 'ch', emoji: '🇨🇭' },
  {
    name: 'Syrian Arab Republic',
    name_cn: '叙利亚阿拉伯共和国',
    code: 'sy',
    emoji: '🇸🇾',
  },
  {
    name: 'Taiwan, Province of China',
    name_cn: '中国台湾',
    code: 'tw',
    emoji: '🇨🇳',
  },
  { name: 'Tajikistan', name_cn: '塔吉克斯坦', code: 'tj', emoji: '🇹🇯' },
  {
    name: 'Tanzania, United Republic of',
    name_cn: '坦桑尼亚联合共和国',
    code: 'tz',
    emoji: '🇹🇿',
  },
  { name: 'Thailand', name_cn: '泰国', code: 'th', emoji: '🇹🇭' },
  { name: 'Timor-Leste', name_cn: '东帝汶', code: 'tl', emoji: '🇹🇱' },
  { name: 'Togo', name_cn: '多哥', code: 'tg', emoji: '🇹🇬' },
  { name: 'Tokelau', name_cn: '托克劳', code: 'tk', emoji: '🇹🇰' },
  { name: 'Tonga', name_cn: '汤加', code: 'to', emoji: '🇹🇴' },
  {
    name: 'Trinidad and Tobago',
    name_cn: '特立尼达和多巴哥',
    code: 'tt',
    emoji: '🇹🇹',
  },
  { name: 'Tunisia', name_cn: '突尼斯', code: 'tn', emoji: '🇹🇳' },
  { name: 'Turkey', name_cn: '土耳其', code: 'tr', emoji: '🇹🇷' },
  { name: 'Turkmenistan', name_cn: '土库曼斯坦', code: 'tm', emoji: '🇹🇲' },
  {
    name: 'Turks and Caicos Islands',
    name_cn: '特克斯和凯科斯群岛',
    code: 'tc',
    emoji: '🇹🇨',
  },
  { name: 'Tuvalu', name_cn: '图瓦卢', code: 'tv', emoji: '🇹🇻' },
  { name: 'Uganda', name_cn: '乌干达', code: 'ug', emoji: '🇺🇬' },
  { name: 'Ukraine', name_cn: '乌克兰', code: 'ua', emoji: '🇺🇦' },
  {
    name: 'United Arab Emirates',
    name_cn: '阿拉伯联合酋长国',
    code: 'ae',
    emoji: '🇦🇪',
  },
  { name: 'United Kingdom', name_cn: '英国', code: 'gb', emoji: '🇬🇧' },
  { name: 'United States', name_cn: '美国', code: 'us', emoji: '🇺🇸' },
  {
    name: 'United States Minor Outlying Islands',
    name_cn: '美国本土外小岛屿',
    code: 'um',
    emoji: '🇺🇲',
  },
  { name: 'Uruguay', name_cn: '乌拉圭', code: 'uy', emoji: '🇺🇾' },
  { name: 'Uzbekistan', name_cn: '乌兹别克斯坦', code: 'uz', emoji: '🇺🇿' },
  { name: 'Vanuatu', name_cn: '瓦努阿图', code: 'vu', emoji: '🇻🇺' },
  {
    name: 'Venezuela, Bolivarian Republic of',
    name_cn: '委内瑞拉玻利瓦尔共和国',
    code: 've',
    emoji: '🇻🇪',
  },
  { name: 'Viet Nam', name_cn: '越南', code: 'vn', emoji: '🇻🇳' },
  {
    name: 'Virgin Islands, British',
    name_cn: '英属维尔京群岛',
    code: 'vg',
    emoji: '🇻🇬',
  },
  {
    name: 'Virgin Islands, U.S.',
    name_cn: '美属维尔京群岛',
    code: 'vi',
    emoji: '🇻🇮',
  },
  {
    name: 'Wallis and Futuna',
    name_cn: '瓦利斯和富图纳',
    code: 'wf',
    emoji: '🇼🇫',
  },
  { name: 'Western Sahara', name_cn: '西撒哈拉', code: 'eh', emoji: '🇪🇭' },
  { name: 'Yemen', name_cn: '也门', code: 'ye', emoji: '🇾🇪' },
  { name: 'Zambia', name_cn: '赞比亚', code: 'zm', emoji: '🇿🇲' },
  { name: 'Zimbabwe', name_cn: '津巴布韦', code: 'zw', emoji: '🇿🇼' },
];

export var Languages = [
  // {
  //   name: '中文（简体）',
  //   name_cn: '中文（简体）',
  //   code: 'zh_cn',
  //   region: 'cn',
  // },
  // { name: '中文（繁體）', name_cn: '中文(繁体)', code: 'zh-TW', region: 'tw' },
  { name: 'English', name_cn: '英语', code: 'en', region: '' },
  // { name: '한국어', name_cn: '韩语', code: 'ko', region: 'kr' },
  // { name: 'Deutsch', name_cn: '德语', code: 'de', region: '' },
  // { name: 'Français', name_cn: '法语', code: 'fr', region: '' },
  // { name: 'Português', name_cn: '葡萄牙语', code: 'pt-PT', region: '' },
  // { name: '日本語', name_cn: '日语', code: 'ja', region: '' },
  // { name: 'Español', name_cn: '西班牙语', code: 'es', region: '' },
  // { name: 'اَلْعَرَبِيَّةُ', name_cn: '阿拉伯语', code: 'ar', region: '' },
  // { name: 'Italiano', name_cn: '意大利语', code: 'it', region: '' },
  // { name: 'Nederlands', name_cn: '荷兰语', code: 'nl', region: '' },
  // { name: 'Polski', name_cn: '波兰语', code: 'pl', region: '' },
  // { name: 'Română', name_cn: '罗马尼亚语', code: 'ro', region: '' },
  // { name: 'Slovenčina', name_cn: '斯洛伐克语', code: 'sk', region: '' },
  // { name: 'Русский язык', name_cn: '俄语', code: 'ru', region: '' },
];
